import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md'

export default ({ pageInfo, offset = 2, hrefBuilder = (pageNumber) => {} }) => {
  const { pageCount, currentPage } = pageInfo
  // const [start,setStart] = useState(1);
  // const [end,setEnd] = useState(1);
  const [range, setRange] = useState([])

  const initPagination = () => {
    let _start = 1
    let _end = 1

    if (pageCount <= 1 + 2 * offset) {
      _start = 1
      _end = pageCount
    } else {
      if (currentPage <= offset) {
        _start = 1
        _end = 1 + 2 * offset
      } else {
        _start = currentPage - offset
        _end = currentPage + offset
      }
      if (end >= pageCount) {
        _start = pageCount - 2 * offset
        _end = pageCount
      }
    }

    let _range = []
    for (let i = _start; i <= _end; i++) {
      _range.push(i)
    }

    setRange(_range)
    // console.log(_range,_start,_end,pageCount)
  }

  useEffect(() => {
    initPagination()
  }, [])

  if (pageCount === 1) {
    return ''
  }

  return (
    <div className="mt-8">
      <ul className="flex items-center justify-center">
        <li
          className={`text-14 px-2 text-gray-99 hover:text-gray-4d ${
            currentPage > 1 ? '' : 'opacity-25'
          }`}
        >
          {currentPage > 1 ? (
            <Link to={hrefBuilder(currentPage - 1)}>
              <MdNavigateBefore />
            </Link>
          ) : (
            <MdNavigateBefore />
          )}
        </li>
        {range.map((page, index) => {
          return (
            <li
              key={`pagination-key-${index}`}
              className={`text-14 px-2 ${
                page === currentPage ? 'text-gray-4d' : 'text-gray-99'
              }`}
            >
              <Link to={hrefBuilder(page)}>{page}</Link>
            </li>
          )
        })}
        <li
          className={`text-14 px-2 text-gray-99 hover:text-gray-4d ${
            currentPage !== pageCount ? '' : 'opacity-25'
          }`}
        >
          {currentPage === pageCount ? (
            <MdNavigateNext />
          ) : (
            <Link to={hrefBuilder(currentPage + 1)}>
              <MdNavigateNext />
            </Link>
          )}
        </li>
      </ul>
    </div>
  )
}
