import React from 'react'

export default ({ children, dark = false }) => {
  return (
    <div
      className={`text-xs font-medium inline-flex px-4 py-2 rounded-full ${
        dark ? '' : 'bg-white text-gray-4d'
      }`}
    >
      {children}
    </div>
  )
}
