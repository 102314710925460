import React from 'react'
import Img from 'gatsby-image'
import ReamMoreButton from '../components/read-more-button'
// import Img from "gatsby-image/withIEPolyfill"

export default ({ title = '', description = '', heroImage = null, link }) => {
  const nl2br = (str, replaceMode, isXhtml) => {
    var breakTag = isXhtml ? '<br />' : '<br>'
    var replaceStr = replaceMode ? '$1' + breakTag : '$1' + breakTag + '$2'
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr)
  }

  return (
    <div className="relative h-screen w-full md:h-42vw md:w-screen">
      {heroImage ? (
        <>
          <Img
            className="h-full w-full md:h-42vw md:w-screen"
            fluid={heroImage.fluid}
          />
        </>
      ) : null}
      <div className="absolute inset-0 px-6 mx-auto md:px-20 text-white flex flex-col justify-end pb-48">
        <div>
          <h3
            className="font-bold text-17-25 md:text-30 md:leading-snug"
            dangerouslySetInnerHTML={{ __html: nl2br(title) }}
          />
          <p
            className="font-regular text-13-18 mt-4 md:text-lg md:leading-relaxed"
            dangerouslySetInnerHTML={{ __html: nl2br(description) }}
          />
          {link ? (
            <div className="mt-5">
              <ReamMoreButton>READ MORE</ReamMoreButton>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}
