import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const CategoryHook = () => {
  const data = useStaticQuery(graphql`
    {
      category: allContentfulCategory(sort: { order: ASC, fields: order }) {
        nodes {
          slug
          title
        }
      }
    }
  `)
  return data
}

export default CategoryHook
