import React from 'react'
import { Link } from 'gatsby'

export default ({ category = [], currentCategory = '', hideEmpty = false }) => {
  return (
    <ul className="flex flex-wrap mt-10 mb-8 md:mt-20 md:mb-16">
      {category.map(({ title, slug }, index) => {
        // console.log(title,blog_post)
        if (hideEmpty) {
          // if(blog_post == null){
          //   return;
          // }
        }

        return (
          <li
            className="cursor-pointer text-14 font-light text-gray-99 md:text-20"
            key={index}
          >
            <Link to={`/stories/${slug}`}>
              <span
                className={`${
                  slug === currentCategory ? 'text-gray-4d' : null
                }`}
              >
                {title}
              </span>
            </Link>
            {index < category.length - 1 ? (
              <span className="px-3">|</span>
            ) : null}
          </li>
        )
      })}
    </ul>
  )
}
