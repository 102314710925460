import React from 'react'
import { Link } from 'gatsby'
import ArticlePreview from '../components/article-preview'
import CategoryLinks from '../components/category-links'
import CategoryHook from '../hooks/category-hook'
import Pagination from '../components/pagination'

export default ({ data, currentCategory }) => {
  const categoryData = CategoryHook()
  const posts = data.allContentfulBlogPost.edges
  const category = categoryData.category.nodes

  return (
    <>
      <Link to="/stories/">
        <h1 className="uppercase font-normal tracking-widest text-gray-33 text-20 md:text-36">
          Stories
        </h1>
      </Link>
      <CategoryLinks category={category} currentCategory={currentCategory} />
      <ul className="posts">
        {posts.map(({ node }) => {
          return (
            <li
              key={node.slug}
              className="mb-6 last:mb-8 md:mb-16 md:last:mb-16"
            >
              <ArticlePreview article={node} />
            </li>
          )
        })}
      </ul>
    </>
  )
}
